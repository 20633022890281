import { MediaHero, TextHero, useClient } from '@animoto/components';
import { isEmailValid } from '@animoto/utils/validators';
import Bowser from 'bowser';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import Cookies from 'js-cookie';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { marginContainer } from './HeroModule.module.css';

export default function HeroModule({
  className,
  data,
  showInputField
}) {
  const {
    backgroundColor,
    copy,
    ctaCopy,
    ctaTrackingClass,
    ctaUrl,
    downloadAppStoreCtaUrl,
    downloadAppStoreTrackingClass,
    firstSupportingTextField,
    forceShowAppStoreBadge,
    hasMarginBottom,
    hideBlobs,
    image,
    imageAlt,
    mediaRatio,
    mobileCtaCopy,
    mobileCtaTrackingClass,
    mobileCtaUrl,
    mobileVideoUrl,
    primaryCtaOpenInNewTab,
    secondaryCtaOpenInNewTab,
    secondaryDesktopCtaCopy,
    secondaryDesktopCtaTrackingClass,
    secondaryDesktopCtaUrl,
    secondaryMobileCtaCopy,
    secondaryMobileCtaTrackingClass,
    secondaryMobileCtaUrl,
    secondSupportingTextField,
    showAppStoreBadge,
    tagline,
    title,
    videoPoster,
    videoUrl
  } = data;

  const [emailValue, setEmailValue] = useState('');
  const [hasAttemptedEmailSubmission, setHasAttemptedEmailSubmission] = useState(false);

  /**
   * Handles input changes
   *
   * @method onChangeInputValue
   *
   * @param {String} attribute Input name
   * @param {String} value     Input value
   */
  const onChangeInputValue = (attribute, value) => {
    if (attribute === 'email') {
      setEmailValue(value);
    }
  };

  /**
   * Handles CTA click
   *
   * @method onClickCta
   *
   */
  const onClickCta = () => {
    if (isEmailValid(emailValue)) {
      Cookies.set('signup_user_email', emailValue);
      window.location = ctaUrl;
    } else {
      setHasAttemptedEmailSubmission(true);
    }
  };

  const iosUser = useClient() && Bowser.parse(window.navigator.userAgent).os.name.includes('iOS');
  const showBadgeOnIosDevice = (showAppStoreBadge && iosUser) || forceShowAppStoreBadge;
  const classes = classNames(className, {
    [marginContainer] : hasMarginBottom
  });

  if (image || videoUrl) {
    return (
      <MediaHero
        background={backgroundColor}
        className={classes}
        copy={copy.copy}
        ctaCopy={ctaCopy}
        ctaTrackingClass={ctaTrackingClass}
        ctaUrl={showInputField ? '' : ctaUrl}
        downloadAppStoreCtaUrl={downloadAppStoreCtaUrl}
        downloadAppStoreTrackingClass={downloadAppStoreTrackingClass}
        email={emailValue}
        firstSupportingTextField={firstSupportingTextField}
        hasAttemptedEmailSubmission={hasAttemptedEmailSubmission}
        hideBlobs={hideBlobs}
        imageAlt={imageAlt}
        imageSrcFluid={image && image.fluid}
        isEmailValid={isEmailValid(emailValue)}
        isIos={showBadgeOnIosDevice}
        mediaRatio={mediaRatio}
        mobileCtaCopy={mobileCtaCopy}
        mobileCtaTrackingClass={mobileCtaTrackingClass}
        mobileCtaUrl={mobileCtaUrl}
        mobileVideoMp4={mobileVideoUrl}
        onChangeInput={onChangeInputValue}
        onClickCta={showInputField ? onClickCta : noop}
        primaryCtaOpenInNewTab={primaryCtaOpenInNewTab}
        secondaryCtaOpenInNewTab={secondaryCtaOpenInNewTab}
        secondaryDesktopCtaCopy={secondaryDesktopCtaCopy}
        secondaryDesktopCtaTrackingClass={secondaryDesktopCtaTrackingClass}
        secondaryDesktopCtaUrl={secondaryDesktopCtaUrl}
        secondaryMobileCtaCopy={secondaryMobileCtaCopy}
        secondaryMobileCtaTrackingClass={secondaryMobileCtaTrackingClass}
        secondaryMobileCtaUrl={secondaryMobileCtaUrl}
        secondSupportingTextField={secondSupportingTextField}
        showInputField={showInputField}
        tagline={tagline}
        title={title}
        videoMp4={videoUrl}
        videoPoster={videoPoster && videoPoster.fluid.srcWebp}
      />
    );
  }
  return (
    <TextHero
      background={backgroundColor}
      className={classes}
      copy={copy.copy}
      desktopCtaCopy={ctaCopy}
      desktopCtaTrackingClass={ctaTrackingClass}
      desktopCtaUrl={ctaUrl}
      downloadAppStoreCtaUrl={downloadAppStoreCtaUrl}
      downloadAppStoreTrackingClass={downloadAppStoreTrackingClass}
      hideBlobs={hideBlobs}
      isIos={showBadgeOnIosDevice}
      mobileCtaCopy={mobileCtaCopy}
      mobileCtaTrackingClass={mobileCtaTrackingClass}
      mobileCtaUrl={mobileCtaUrl}
      primaryCtaOpenInNewTab={primaryCtaOpenInNewTab}
      secondaryCtaOpenInNewTab={secondaryCtaOpenInNewTab}
      secondaryDesktopCtaCopy={secondaryDesktopCtaCopy}
      secondaryDesktopCtaTrackingClass={secondaryDesktopCtaTrackingClass}
      secondaryDesktopCtaUrl={secondaryDesktopCtaUrl}
      secondaryMobileCtaCopy={secondaryMobileCtaCopy}
      secondaryMobileCtaTrackingClass={secondaryMobileCtaTrackingClass}
      secondaryMobileCtaUrl={secondaryMobileCtaUrl}
      tagline={tagline}
      title={title}
    />
  );
}

export const HeroDataPropsObject = {
  backgroundColor : PropTypes.string,
  copy            : PropTypes.shape({
    copy : PropTypes.string
  }),
  createdAt                     : PropTypes.string,
  ctaCopy                       : PropTypes.string,
  ctaTrackingClass              : PropTypes.string,
  ctaUrl                        : PropTypes.string,
  downloadAppStoreCtaUrl        : PropTypes.string,
  downloadAppStoreTrackingClass : PropTypes.string,
  firstSupportingTextField      : PropTypes.string,
  forceShowAppStoreBadge        : PropTypes.bool,
  hasMarginBottom               : PropTypes.bool,
  hideBlobs                     : PropTypes.bool,
  image                         : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number,
      sizes       : PropTypes.string,
      src         : PropTypes.string,
      srcSet      : PropTypes.string,
      srcSetWebp  : PropTypes.string,
      srcWebp     : PropTypes.string
    })
  }),
  imageAlt                         : PropTypes.string,
  mediaRatio                       : PropTypes.string,
  mobileCtaCopy                    : PropTypes.string,
  mobileCtaTrackingClass           : PropTypes.string,
  mobileCtaUrl                     : PropTypes.string,
  mobileVideoUrl                   : PropTypes.string,
  primaryCtaOpenInNewTab           : PropTypes.bool,
  secondaryCtaOpenInNewTab         : PropTypes.bool,
  secondaryDesktopCtaCopy          : PropTypes.string,
  secondaryDesktopCtaTrackingClass : PropTypes.string,
  secondaryDesktopCtaUrl           : PropTypes.string,
  secondaryMobileCtaCopy           : PropTypes.string,
  secondaryMobileCtaTrackingClass  : PropTypes.string,
  secondaryMobileCtaUrl            : PropTypes.string,
  secondSupportingTextField        : PropTypes.string,
  showAppStoreBadge                : PropTypes.bool,
  tagline                          : PropTypes.string,
  title                            : PropTypes.string,
  videoPoster                      : PropTypes.shape({
    fluid : PropTypes.shape({
      srcWebp : PropTypes.string
    })
  }),
  videoSchemaContentUrl  : PropTypes.string,
  videoSchemaDescription : PropTypes.shape({
    videoSchemaDescription : PropTypes.string
  }),
  videoSchemaName      : PropTypes.string,
  videoSchemaThumbnail : PropTypes.shape({
    file : PropTypes.shape({
      url : PropTypes.string
    })
  }),
  videoSchemaTranscript : PropTypes.shape({
    videoSchemaTranscript : PropTypes.string
  }),
  videoUrl : PropTypes.string
};

HeroModule.propTypes = {
  className      : PropTypes.string,
  data           : PropTypes.shape(HeroDataPropsObject).isRequired,
  showInputField : PropTypes.bool
};

HeroModule.defaultProps = {
  className      : null,
  showInputField : false
};

export const HeroModuleData = graphql`
   fragment HeroModuleData on ContentfulComponentHero {
    backgroundColor
    copy {
      copy
    }
    createdAt
    ctaCopy
    ctaTrackingClass
    ctaUrl
    downloadAppStoreCtaUrl
    downloadAppStoreTrackingClass
    firstSupportingTextField
    forceShowAppStoreBadge
    hasMarginBottom
    hideBlobs
    image {
      fluid(quality: 80, maxWidth: 565, maxHeight: 330) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    imageAlt
    mediaRatio
    mobileCtaCopy
    mobileCtaTrackingClass
    mobileCtaUrl
    mobileVideoUrl
    primaryCtaOpenInNewTab
    secondaryCtaOpenInNewTab 
    secondaryDesktopCtaCopy
    secondaryDesktopCtaTrackingClass
    secondaryDesktopCtaUrl
    secondaryMobileCtaCopy
    secondaryMobileCtaTrackingClass
    secondaryMobileCtaUrl
    secondSupportingTextField
    showAppStoreBadge
    tagline
    title
    videoPoster {
      fluid(quality: 80, maxWidth: 565, maxHeight: 330) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    videoSchemaContentUrl
    videoSchemaDescription {
      videoSchemaDescription
    }
    videoSchemaName
    videoSchemaThumbnail {
      file {
        url
      }
    }
    videoSchemaTranscript {
      videoSchemaTranscript
    }
    videoUrl
  }
`;
